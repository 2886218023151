import { NavLink } from 'react-router-dom'
import React, { Component } from 'react'
import { closeLoader, openLoader } from '@actions/loader'
import { fetchReports, getNextReports, setCurrentCampaign, syncData } from '@actions/report'

import PermissionsChecker from '@shared/PermissionsChecker'
import ReportFilter from '@components/ReportFilter'
import ReportTable from '@components/ReportTable'
import RolesChecker from '@shared/RolesChecker'
import _ from 'underscore'
import { connect } from 'react-redux'
import reportColumns from '@components/ReportTable/reportColumns'
import reportFilters from '@components/ReportTable/reportFilters'
import { withRouter } from '../../utils/withRouter'
import SelectSchoolInput from '@components/SelectSchoolInput'
import { fetchSchools } from '@actions/school'

class Report extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isReady: false,
      filters: [],
      selectedSchool: null,
      selectedSchoolId: null,
      timeOut: () => {},
    }

    this.functionBullshit = this.functionBullshit.bind(this)
    this._isMounted = false
  }

  _filterData() {
    return new Promise((resolve, reject) => {
      if (this.state.isReady) {
        let combined = {}

        if (this.state.filters.length) {
          combined = Object.assign(...this.state.filters.map(filter => filter))
        }

        this.props
          .fetchReports(this.props.url, combined, this.props.type)
          .then(resolve)
          .catch(reject)
          .finally(() => {
            this.props.closeLoader()
            this.props.onFilterLoaded && this.props.onFilterLoaded()
          })
      } else {
        this.props.onFilterLoaded && this.props.onFilterLoaded()
        resolve()
      }
    })
  }

  functionBullshit(filterValue) {
    clearTimeout(this.state.timeOut)

    let filters = this.state.filters
    filters.push(filterValue)

    this._isMounted &&
      this.setState({
        filters,
        timeOut: setTimeout(() => {
          this.props.openLoader()
          this._filterData()
        }, 750),
      })
  }

  componentDidMount() {
    this.props.fetchSchools()
    this._isMounted = true
    setTimeout(() => {
      this._isMounted &&
        this.setState(
          {
            isReady: true,
          },
          () => {
            this._filterData()
          },
        )
    }, 1000)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { title, subTitle, type, url, setCurrentCampaign, report, exportId, location } = this.props
    const currentCampaign = report.currentCampaign.campaign_id

    let combined = {}
    if (this.state.filters.length) {
      combined = Object.assign(...this.state.filters.map(filter => filter))
    }

    let params = {
      page: +this.props.report.list.page,
      ...combined,
    }

    let loadParams = {
      page: 1,
    }
 
 
    const allSchools = JSON.parse(localStorage.getItem('reduxPersist:school') ? localStorage.getItem('reduxPersist:school') : '{}');
    const session = JSON.parse(localStorage.getItem('reduxPersist:sessionReducer'));
    var visualizedSchools = [];
    if (allSchools.schools) {    
      visualizedSchools = (allSchools.schools).filter(school => [(session.user.school_id), ...((session.user.schools))].includes(school.value))
    }

    return (
      <div className={`report ${type}`}>
        <h1 className="report__title">{title}</h1>
        {type === 'users' && (
          <PermissionsChecker permissions={['create_users']}>
            <NavLink className="button__small" to="/admin/novo">
              Adicionar novo usuário
            </NavLink>
          </PermissionsChecker>
        )}
        {!!reportFilters[type] && (
          <div>
            {
              (type === 'orders' && visualizedSchools.length > 1) && (
                <div style={{ marginTop: '20px' }}>
                  <SelectSchoolInput
                    value={this.state.selectedSchool}
                    setInputValue={(e) => {
                      this.setState({ selectedSchool: e })
                      var schoolData = (visualizedSchools.find(school => school.label === e))
                      if (schoolData){
                        this.setState({ selectedSchoolId: schoolData.value })
                      }
                    }}
                    setState={(e) => {
                      this.functionBullshit({ school_id: e })
                    }}
                    schools={visualizedSchools}
                  />
                </div>
              )
            }

            <div className="filters" id="report_container">
              {reportFilters[type].map((filter, index) => (
                <RolesChecker key={index} roles={filter.roles}>
                  <div className="filters__item">
                    <ReportFilter
                      setCurrentCampaign={campaign => setCurrentCampaign(campaign)}
                      type={filter.name}
                      onChange={this.functionBullshit}
                    />
                  </div>
                </RolesChecker>
              ))}
            </div>
          </div>
        )}
        {type === 'vouchers' && location.pathname.match(/admin\/escolas\/\d+/) && (
          <PermissionsChecker permissions={['create_vouchers']}>
            <NavLink className="button__small" to={`/admin/escolas/${exportId}/voucher/novo`}>
              Criar voucher
            </NavLink>
          </PermissionsChecker>
        )}
        <div className="report__table">
          <ReportTable
            isReady={this.state.isReady}
            selectedSchoolId={this.state.selectedSchoolId}
            title={subTitle}
            loadData={() => this.state.isReady && this.props.fetchReports(url, loadParams, type)}
            loadMore={() => this.state.isReady && this.props.getNextReports(url, params, type)}
            syncData={(endpoint, id) => this.state.isReady && this.props.syncData(endpoint, type, id)}
            data={this.props.report.list[type] || []}
            columns={reportColumns[type]}
            user={this.props.user}
            reportType={this.props.type}
            currentCampaign={currentCampaign}
            exportId={typeof this.props.exportId !== 'undefined' ? this.props.exportId : ''}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sessionReducer: state.sessionReducer.user,
  report: state.report,
  user: state.sessionReducer.user,
})

const mapDispatchToProps = dispatch => ({
  fetchSchools: () => dispatch(fetchSchools()),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  getNextReports: (url, params, listType) => {
    return dispatch(getNextReports(url, params, listType))
  },
  fetchReports: (url, params, listType) => {
    return dispatch(fetchReports(url, params, listType))
  },
  syncData: (endpoint, listType, id) => {
    return dispatch(syncData(endpoint, listType, id))
  },
  setCurrentCampaign: campaign => dispatch(setCurrentCampaign(campaign)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Report))

