import { Cell, Column, Table } from 'fixed-data-table-2'
import { NavLink } from 'react-router-dom'
import React, { Component } from 'react'
import { blobDownload, changeVoucherStatus } from '@services/sm-api.js'
import { closeLoader, openLoader } from '@actions/loader'

import FileDownloader from '@shared/FileDownloader'
import { Modal } from 'react-bootstrap'
import PermissionsChecker from '@shared/PermissionsChecker'
import _ from 'underscore'
import { connect } from 'react-redux'
import export_report from './images/export_report.png'
import moment from 'moment'
import { withRouter } from '../../utils/withRouter'

const VOUCHER_ACTIVE = 1
const VOUCHER_INACTIVE = 0

const TextCell = ({ data, rowIndex, columnKey, fieldName, formatter, ...props }) => {
  let text = !!data[rowIndex] ? data[rowIndex][columnKey] : '-'

  if (typeof text === 'object') {
    text = JSON.stringify(text)
  }

  if ([null, undefined, 'null'].includes(text)) {
    text = '-'
  }

  if (fieldName === 'order_id') {
    text = `SMD-${text}`
  }

  if (_.isFunction(formatter)) text = formatter(text)

  return (
    <Cell title={text.length > 20 ? text : ''} className="report-table__text-cell" {...props}>
      {text}
    </Cell>
  )
}

const ButtonCellSync = ({ data, label, className, rowIndex, handleSync, fieldName, ...props }) => {
  return (
    <Cell className="report-table__button-cell" {...props}>
      <React.Fragment>
        <PermissionsChecker permissions={['sync_log_error']}>
          <button
            className={className}
            onClick={() => {
              !!data[rowIndex] && handleSync(data[rowIndex][fieldName])
            }}
          >
            {label}
          </button>
        </PermissionsChecker>
        <PermissionsChecker permissions={['sync_log_error']} negate>
          <button className={className} disabled>
            {label}
          </button>
        </PermissionsChecker>
      </React.Fragment>
    </Cell>
  )
}

const ButtonCell = ({ url, data, rowIndex, fieldName, reportType = '', label, className, ...props }) => {
  return (
    <Cell className={`report-table__button-cell`} {...props}>
      {!!data[rowIndex] && (
        <>
          {reportType === 'users' ? (
            <React.Fragment>
              <PermissionsChecker permissions={['access_user_details']}>
                <NavLink to={`${url}/${data[rowIndex][fieldName]}`} className={className}>
                  {label}
                </NavLink>
              </PermissionsChecker>
              <PermissionsChecker permissions={['access_user_details']} negate>
                <NavLink disabled to={`${url}/${data[rowIndex][fieldName]}`} className={`${className} disabled`}>
                  {label}
                </NavLink>
              </PermissionsChecker>
            </React.Fragment>
          ) : (
            <NavLink to={`${url}/${data[rowIndex][fieldName]}`} className={className}>
              {label}
            </NavLink>
          )}
        </>
      )}
    </Cell>
  )
}

const SyncModalMessage = props => {
  const listMessages = props.messages.map(message => <li>{message}</li>)
  return <ul>{listMessages}</ul>
}

const HeaderCell = ({ ...props }) => (
  <Cell className="report-table__header-cell" {...props}>
    {props.children}
  </Cell>
)

const ButtonCellVoucherStatus = ({ data, rowIndex, handleGetVoucher, handleChangeVoucherStatus, className }) => {
  const voucher = handleGetVoucher(data[rowIndex]?.name)
  if (!voucher) {
    return null
  }
  let voucherStatus = _.property(['status'])(voucher) === VOUCHER_ACTIVE ? 'active' : 'inactive'
  return (
    <Cell className={`report-table__button-cell ${className}`}>
      <PermissionsChecker permissions={['manage_voucher_status']}>
        <button
          className={`button report-table__voucher-status ${voucherStatus}`}
          onClick={() => {
            handleChangeVoucherStatus(voucher)
          }}
        >
          {voucher.label}
        </button>
      </PermissionsChecker>
      <PermissionsChecker permissions={['manage_voucher_status']} negate>
        {voucher.label}
      </PermissionsChecker>
    </Cell>
  )
}

class ReportTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      syncResultModalOpen: false,
      syncResultModalMessage: null,
      syncModalMessages: [],
      vouchersMap: {},
    }

    this._handleSync = this._handleSync.bind(this)
  }

  componentDidMount() {
    setTimeout(() => this.props.loadData(), 1000)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.exportId !== nextProps.exportId) nextProps.loadData()
    if (nextProps.data.length) {
      let vouchersMap = this.state.vouchersMap || {}

      for (let i = 0; i < nextProps.data.length; i++) {
        vouchersMap[nextProps.data[i].name] = {
          ...nextProps.data[i],
          status: vouchersMap[nextProps.data[i].name]
            ? vouchersMap[nextProps.data[i].name].status
            : nextProps.data[i].status,
          label: vouchersMap[nextProps.data[i].name]
            ? vouchersMap[nextProps.data[i].name].label
            : nextProps.data[i].status === VOUCHER_ACTIVE
            ? 'Ativo'
            : 'Inativo',
        }
      }
      this.setState({
        vouchersMap: vouchersMap,
      })
    }
  }

  _getVoucher(voucherName) {
    return this.state.vouchersMap[voucherName]
  }

  _setVoucher(voucher) {
    let vouchers = this.state.vouchersMap
    vouchers[voucher.name] = voucher
    this.setState({
      vouchersMap: vouchers,
    })
  }

  _handleChangeVoucherStatus(voucher) {
    const newStatus = voucher.status === VOUCHER_ACTIVE ? VOUCHER_INACTIVE : VOUCHER_ACTIVE
    this.props.openLoader()
    return changeVoucherStatus(voucher.name, { status: newStatus })
      .then(response => {
        if (response.code === 201) {
          voucher.status = newStatus
          voucher.label = newStatus === VOUCHER_ACTIVE ? 'Ativo' : 'Inativo'
          this._setVoucher(voucher)
        }
      })
      .finally(() => this.props.closeLoader())
  }

  _handleReportDownload(reportType, options) {
    this.props.openLoader()
    const { currentCampaign, reportExportUri, id, selectedSchoolId } = options
    let url = `${reportExportUri}/report/export/${id}`

    let params = {}
    if (reportType === 'orders') {
      params.campaign_id = currentCampaign
      params.selectedSchoolId = selectedSchoolId
      url = 'orders/list/export'
    }
    return blobDownload(url, params).finally(() => this.props.closeLoader())
  }

  _showSyncResultModal(messages) {
    if (!this.state.syncResultModalOpen) {
      this.setState({
        syncResultModalOpen: true,
        syncModalMessages: messages,
      })
    }
  }

  _handleLoadMore(event) {
    let currentScroll = this.refs.Table.state.scrollY
    let maxScroll = this.refs.Table.state.maxScrollY
    if (currentScroll === maxScroll) {
      this.props.loadMore()
    }
  }

  _handleSync(syncId, url) {
    let endpoint = `${url}/${syncId}`

    let itemRow = this.props.data.filter(row => {
      return row.formatted_order_id === syncId
    })

    if (itemRow) {
      itemRow = itemRow[0]
    }

    this.props.syncData(endpoint, syncId).then(response => {
      if (response[0].sap) {
        let message
        if (response[0].sap.Zreturn === 0) {
          message = ['Pedido enviado para o SAP']
          itemRow.deleted = true
        } else {
          message = ['Erro ao enviar pedido para o SAP']
          if (response[0].sap.Zmesg) {
            message.push(response[0].sap.Zmesg)
            itemRow.sap_return_message = response[0].sap.Zmesg
          }
        }
        this._showSyncResultModal(message)
      }
    })
  }

  _formatExportServiceUri(reportType) {
    switch (reportType) {
      case 'vouchers':
        return 'voucher'
      case 'sap_order_log':
        return 'orders/sap'
      case 'schools':
        return `contracts/${reportType}`
      default:
        return reportType
    }
  }

  _getExportEndpointId(reportType, user, exportId) {
    switch (reportType) {
      case 'vouchers':
        if (exportId === '') {
          return user.school_id
        }

        return exportId
      default:
        return user.id
    }
  }

  render() {
    let { data, columns, title, user, reportType, currentCampaign, exportId, location,  selectedSchoolId} = this.props
    let reportExportUri = this._formatExportServiceUri(reportType)
    let id = this._getExportEndpointId(reportType, user, this.props.exportId)

    data = data.filter(row => {
      return !row.deleted
    })

    let editVoucher = true
    if (!location.pathname.match(/admin\/escolas\/\d+/)) {
      editVoucher = false
      for (var i = 0; i < columns.length; i++) {
        if (columns[i]['field'] === 'voucher_edit') {
          columns.splice(i, 1)
          break
        }
      }
    }

    return (
      <div className="report-table">
        <div className="report-table__header">
          <h1 className="report-table__title">{title}</h1>
          {['enqueue', 'log'].indexOf(reportType) === -1 ? (
            <FileDownloader
              className="report-table__export-title"
              onClick={() =>
                this._handleReportDownload(reportType, {
                  currentCampaign,
                  reportExportUri,
                  id,
                  selectedSchoolId,
                })
            }
              filename={`${reportType}-${moment().format('x')}.xls`}
            >
              <span className="report-table__export-title-label"> Exportar Dados </span>
              <img className="report__export" src={export_report} alt="Botão Exportar" />
            </FileDownloader>
          ) : null}
        </div>
        <Table
          ref="Table"
          className="report-table"
          onScrollEnd={() => {
            this._handleLoadMore()
          }}
          rowHeight={60}
          rowsCount={data.length}
          width={960}
          height={400}
          headerHeight={50}
          data={data}
        >
          {columns.map((column, key) => {
            return (
              <Column
                key={key}
                header={<HeaderCell className={column.className || ''}>{column.label}</HeaderCell>}
                columnKey={column.field}
                cell={
                  column.field === 'order_id' ? (
                    <TextCell
                      data={data}
                      formatter={column.formatter}
                      fieldName={column.field}
                      className={column.className || ''}
                    />
                  ) : column.field === 'details' ? (
                    <ButtonCell
                      reportType={reportType}
                      data={data}
                      label="ver detalhes"
                      className={
                        column.className
                          ? `button button__small ${reportType} ${column.className}`
                          : `button button__small ${reportType}`
                      }
                      fieldName={column.concat_field}
                      url={`${column.url}`}
                    />
                  ) : column.field === 'voucher_status' ? (
                    <ButtonCellVoucherStatus
                      handleGetVoucher={voucherName => this._getVoucher(voucherName)}
                      handleChangeVoucherStatus={voucher =>
                        this._handleChangeVoucherStatus(voucher).then(() => {
                          this.props.onVoucherChanged && this.props.onVoucherChanged()
                        })
                      }
                      className={column.className || ''}
                      data={data}
                    />
                  ) : column.field === 'voucher_edit' && editVoucher ? (
                    <ButtonCell
                      data={data}
                      reportType={reportType}
                      label="Editar"
                      className={column.className ? `button button__small ${column.className}` : 'button button__small'}
                      fieldName={column.concat_field}
                      url={`/admin/escolas/${exportId}/voucher/edit`}
                    />
                  ) : column.field === 'sync' ? (
                    <ButtonCellSync
                      data={data}
                      handleSync={syncId => {
                        this._handleSync(syncId, column.url)
                      }}
                      fieldName={column.concat_field}
                      label="sincronizar"
                      className={column.className ? `button button__sync ${column.className}` : 'button button__sync'}
                    />
                  ) : column.field === 'business_line' ? (
                    <TextCell
                      data={data}
                      formatter={column.formatter}
                      fieldName={column.field}
                      className={column.className || ''}
                    />
                  ) : (
                    <TextCell
                      data={data}
                      formatter={column.formatter}
                      fieldName={column.field}
                      className={column.className || ''}
                    />
                  )
                }
                fixed
                width={960 / columns.length}
              />
            )
          })}
        </Table>

        <Modal show={this.state.syncResultModalOpen}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Sincronização de pedido</h1>
            </div>

            <div className="modalContent">
              <SyncModalMessage messages={this.state.syncModalMessages} />
            </div>

            <div className="modalFooter">
              <button
                className="modal-close"
                onClick={() => {
                  this.setState({
                    syncResultModalOpen: false,
                  })
                }}
              >
                Entendi
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
})

export default withRouter(connect(null, mapDispatchToProps)(ReportTable))

